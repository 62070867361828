<template lang="pug">
  BBadge.badge {{ text }}
</template>

<script>
  export default {
    props: {
      text: String
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .badge
    background: $default-red
    border-radius: 12.5px
    font-size: 0.8rem
    color: $default-white
    text-align: center
    line-height: 14px
    padding: 5px 14px 5px
    font-weight: 300
</style>
